import React from 'react'
import { useMediaQuery } from 'react-responsive'
import { useIntl, FormattedMessage } from 'gatsby-plugin-intl'
import componentStyles from './competencyTable.module.scss'
import { FaCheckCircle, FaInfoCircle } from 'react-icons/fa'
import CompetencyHeader from './CompetencyHeader'

const CompetencyTable = () => {
  const intl = useIntl()
  const isScreenSm = useMediaQuery({ maxWidth: 765 })
  return (
    <>
      {isScreenSm ? (
        <>
          <div className='tile'>
            <p>
              <CompetencyHeader
                id='businessPlanning'
                header={<FormattedMessage id='becomeBuilder2.list03A' />}
              >
                <FormattedMessage id='competencyRequirements.toolTipA' />
              </CompetencyHeader>
            </p>
            <ul>
              <li>
                <a href='https://www.phbi.ca/on/hcra-courses/hcra-courses/'>
                  Training Ontario
                </a>
              </li>
              <li>
                <a href='https://corporate.algonquincollege.com/group/home-construction-regulatory-authority-courses-exams/'>
                  Algonquin College Corporate Training
                </a>
              </li>
              <li>
                <a href=' https://www.trainingbuilders.ca/'>buildABILITY</a>
              </li>
            </ul>
          </div>

          <div className='tile'>
            <p>
              <CompetencyHeader
                id='financialPlanning'
                header={<FormattedMessage id='becomeBuilder2.list03B' />}
              >
                <FormattedMessage id='competencyRequirements.toolTipB' />
              </CompetencyHeader>
            </p>
            <ul>
              <li>
                <a href='https://www.phbi.ca/on/hcra-courses/hcra-courses/'>
                  Training Ontario
                </a>
              </li>
              <li>
                <a href='https://corporate.algonquincollege.com/group/home-construction-regulatory-authority-courses-exams/'>
                  Algonquin College Corporate Training
                </a>
              </li>
              <li>
                <a href='https://www.trainingbuilders.ca/'>buildABILITY</a>
              </li>
            </ul>
          </div>

          <div className='tile'>
            <p>
              <CompetencyHeader
                id='projectManagement'
                header={<FormattedMessage id='becomeBuilder2.list03C' />}
              >
                <FormattedMessage id='competencyRequirements.toolTipC' />
              </CompetencyHeader>
            </p>
            <ul>
              <li>
                <a href='https://www.phbi.ca/on/hcra-courses/'>
                  Training Ontario
                </a>
              </li>
              <li>
                <a href='https://corporate.algonquincollege.com/group/home-construction-regulatory-authority-courses-exams/'>
                  Algonquin College Corporate Training
                </a>
              </li>
              <li>
                <a href=' https://www.trainingbuilders.ca/'>buildABILITY</a>
              </li>
            </ul>
          </div>

          <div className='tile'>
            <p>
              <CompetencyHeader
                id='legalIssues'
                header={<FormattedMessage id='becomeBuilder2.list03D' />}
              >
                <FormattedMessage id='competencyRequirements.toolTipD' />
              </CompetencyHeader>
            </p>
            <ul>
              <li>
                <a href='https://www.phbi.ca/on/hcra-courses/'>
                  Training Ontario
                </a>
              </li>
              <li>
                <a href='https://corporate.algonquincollege.com/group/home-construction-regulatory-authority-courses-exams/'>
                  Algonquin College Corporate Training
                </a>
              </li>
              <li>
                <a href='https://www.trainingbuilders.ca/'>buildABILITY</a>
              </li>
            </ul>
          </div>

          <div className='tile'>
            <p>
              <CompetencyHeader
                id='buildingCodes'
                header={<FormattedMessage id='becomeBuilder2.list03E' />}
              >
                <FormattedMessage id='competencyRequirements.toolTipE' />
              </CompetencyHeader>
            </p>
            <ul>
              <li>
                <a href='https://www.phbi.ca/on/hcra-courses/'>
                  Training Ontario
                </a>
              </li>
              <li>
                <a href='https://corporate.algonquincollege.com/group/home-construction-regulatory-authority-courses-exams/'>
                  Algonquin College Corporate Training
                </a>
              </li>
              <li>
                <a href='https://www.trainingbuilders.ca/'>buildABILITY</a>
              </li>
            </ul>
          </div>

          <div className='tile'>
            <p>
              <CompetencyHeader
                id='constructionTech'
                header={<FormattedMessage id='becomeBuilder2.list03F' />}
              >
                <FormattedMessage id='competencyRequirements.toolTipF' />
              </CompetencyHeader>
            </p>
            <ul>
              <li>
                <a href='https://www.phbi.ca/on/hcra-courses/'>
                  Training Ontario
                </a>
              </li>
              <li>
                <a href='https://corporate.algonquincollege.com/group/home-construction-regulatory-authority-courses-exams/'>
                  Algonquin College Corporate Training
                </a>
              </li>
              <li>
                <a href='https://www.trainingbuilders.ca/'>buildABILITY</a>
              </li>
            </ul>
          </div>

          <div className='tile'>
            <p>
              <CompetencyHeader
                id='customerService'
                header={<FormattedMessage id='becomeBuilder2.list03G' />}
              >
                <FormattedMessage id='competencyRequirements.toolTipG' />
              </CompetencyHeader>
            </p>
            <ul>
              <li>
                <a href='https://www.phbi.ca/on/hcra-courses/'>
                  Training Ontario
                </a>
              </li>

              <li>
                <a href='https://corporate.algonquincollege.com/group/home-construction-regulatory-authority-courses-exams/'>
                  Algonquin College Corporate Training
                </a>
              </li>
              <li>
                <a href='https://www.trainingbuilders.ca/'>buildABILITY</a>
              </li>
            </ul>
          </div>
        </>
      ) : (
        <table>
          <thead>
            <tr>
              <th aria-label='course provider'></th>
              <th scope='col' style={{ textAlign: `center` }}>
                <a href='https://www.phbi.ca/on/hcra-courses/'>
                  Training Ontario
                </a>
              </th>
              <th scope='col' style={{ textAlign: `center` }}>
                <a href='https://corporate.algonquincollege.com/group/home-construction-regulatory-authority-courses-exams/'>
                  Algonquin College Corporate Training
                </a>
              </th>
              <th scope='col' style={{ textAlign: `center` }}>
                <a href='https://www.trainingbuilders.ca/'>buildABILITY</a>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope='row' className={componentStyles.headerCell}>
                <CompetencyHeader
                  id='businessPlanning'
                  header={<FormattedMessage id='becomeBuilder2.list03A' />}
                >
                  <FormattedMessage id='competencyRequirements.toolTipA' />
                </CompetencyHeader>
              </th>
              <td>
                {/* Online Course with Online Assessment */}
                <FaCheckCircle
                  className='largeIcon'
                  aria-label='offers course'
                />
              </td>
              <td>
                {/* Live Webinars */}
                <FaCheckCircle
                  className='largeIcon'
                  aria-label='offers course'
                />
              </td>
              <td>
                {/* Online Course */}
                <FaCheckCircle
                  className='largeIcon'
                  aria-label='offers course'
                />
              </td>
            </tr>

            <tr>
              <th scope='row' className={componentStyles.headerCell}>
                <CompetencyHeader
                  id='financialPlanning'
                  header={<FormattedMessage id='becomeBuilder2.list03B' />}
                >
                  <FormattedMessage id='competencyRequirements.toolTipB' />
                </CompetencyHeader>
              </th>
              <td>
                {/* Online Course with Online Assessment */}
                <FaCheckCircle
                  className='largeIcon'
                  aria-label='offers course'
                />
              </td>
              <td>
                {/* Live Webinars */}
                <FaCheckCircle
                  className='largeIcon'
                  aria-label='offers course'
                />
              </td>
              <td>
                {/* Online Course */}
                <FaCheckCircle
                  className='largeIcon'
                  aria-label='offers course'
                />
              </td>
            </tr>

            <tr>
              <th scope='row' className={componentStyles.headerCell}>
                <CompetencyHeader
                  id='projectManagement'
                  header={<FormattedMessage id='becomeBuilder2.list03C' />}
                >
                  <FormattedMessage id='competencyRequirements.toolTipC' />
                </CompetencyHeader>
              </th>
              <td>
                {/* Online Course with Online Assessment */}
                <FaCheckCircle
                  className='largeIcon'
                  aria-label='offers course'
                />
              </td>
              <td>
                <FaCheckCircle
                  className='largeIcon'
                  aria-label='offers course'
                />
              </td>
              <td>
                {/* Online Course */}
                <FaCheckCircle
                  className='largeIcon'
                  aria-label='offers course'
                />
              </td>
            </tr>

            <tr>
              <th scope='row' className={componentStyles.headerCell}>
                <CompetencyHeader
                  id='legalIssues'
                  header={<FormattedMessage id='becomeBuilder2.list03D' />}
                >
                  <FormattedMessage id='competencyRequirements.toolTipD' />
                </CompetencyHeader>
              </th>
              <td>
                {/* Online Course with Online Assessment */}
                <FaCheckCircle
                  className='largeIcon'
                  aria-label='offers course'
                />
              </td>
              <td>
                <FaCheckCircle
                  className='largeIcon'
                  aria-label='offers course'
                />
              </td>
              <td>
                {/* Online Course */}
                <FaCheckCircle
                  className='largeIcon'
                  aria-label='offers course'
                />
              </td>
            </tr>

            <tr>
              <th scope='row' className={componentStyles.headerCell}>
                <CompetencyHeader
                  id='buildingCodes'
                  header={<FormattedMessage id='becomeBuilder2.list03E' />}
                >
                  <FormattedMessage id='competencyRequirements.toolTipE' />
                </CompetencyHeader>
              </th>
              <td>
                {/* Online Course with Online Assessment */}
                <FaCheckCircle
                  className='largeIcon'
                  aria-label='offers course'
                />
              </td>
              <td>
                <FaCheckCircle
                  className='largeIcon'
                  aria-label='offers course'
                />
              </td>
              <td>
                {/* Online Course */}
                <FaCheckCircle
                  className='largeIcon'
                  aria-label='offers course'
                />
              </td>
            </tr>

            <tr>
              <th scope='row' className={componentStyles.headerCell}>
                <CompetencyHeader
                  id='constructionTech'
                  header={<FormattedMessage id='becomeBuilder2.list03F' />}
                >
                  <FormattedMessage id='competencyRequirements.toolTipF' />
                </CompetencyHeader>
              </th>
              <td>
                {/* Online Course with Online Assessment */}
                <FaCheckCircle
                  className='largeIcon'
                  aria-label='offers course'
                />
              </td>
              <td>
                <FaCheckCircle
                  className='largeIcon'
                  aria-label='offers course'
                />
              </td>
              <td>
                {/* Online Course */}
                <FaCheckCircle
                  className='largeIcon'
                  aria-label='offers course'
                />
              </td>
            </tr>

            <tr>
              <th scope='row' className={componentStyles.headerCell}>
                <CompetencyHeader
                  id='customerService'
                  header={<FormattedMessage id='becomeBuilder2.list03G' />}
                >
                  <FormattedMessage id='competencyRequirements.toolTipG' />
                </CompetencyHeader>
              </th>
              <td>
                {/* Online Course with Online Assessment */}
                <FaCheckCircle
                  className='largeIcon'
                  aria-label='offers course'
                />
              </td>
              <td>
                {/* Live Webinars */}
                <FaCheckCircle
                  className='largeIcon'
                  aria-label='offers course'
                />
              </td>
              <td>
                {/* Online Course */}
                <FaCheckCircle
                  className='largeIcon'
                  aria-label='offers course'
                />
              </td>
            </tr>
          </tbody>
        </table>
      )}
      <p className='footnote'>
        *<FormattedMessage id='competencyRequirements.footenoteA' />
      </p>
    </>
  )
}

export default CompetencyTable
